<template>

  <!--begin::Col-->
  <div class="col-xl-8 mb-xl-8">
    <div class="card h-md-100">
      <div class="card-header align-items-center border-0">
        <h3 class="fw-bold text-gray-900 m-0">平台优选</h3>
      </div>
      <div class="card-body pt-2">
        <ul class="nav nav-pills nav-pills-custom mb-3">
          <li class="nav-item mb-3 me-3 me-lg-6" v-for="(item, index) in navList" :key="index">
            <a class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
               :class="index===0?'active':''"
               data-bs-toggle="pill" :href="`#kt_stats_widget_2_tab_${index+1}`">
              <div class="nav-icon">
                <img alt="" :src="item.imagesSrc" class=""/>
              </div>
              <span class="nav-text text-gray-700 fw-bold fs-7 lh-1">
                        {{ item.title.substring(0, 4) }}
                    </span>
              <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade" :id="`kt_stats_widget_2_tab_${index+1}`" v-for="(item, index) in navList"
               :key="index" :class="index===0?'show active':''">
            <div class="table-responsive">
              <table class="table table-row-dashed align-middle gs-0 gy-4 my-0">
                <thead>
                <tr class="fs-7 fw-bold text-gray-500 border-bottom-0">
                  <th class="">商品信息</th>
                  <th class="pe-0 text-center min-w-100px">销售价</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(i, index) in item.list" :key="index">
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="javascript:void(0);" class="symbol symbol-50px">
                        <img class="symbol-label lozad" :src="i.imagesUrl"/>
                        <!--                        <img class="symbol-label lozad" v-if="i.imagesUrl.indexOf('http') == 0"-->
                        <!--                             :src="`${i.imagesUrl}`"/>-->
                        <!--                        <img class="symbol-label lozad" v-else :src="`https://www.3jzc.cn${i.imagesUrl}`"/>-->
                      </a>
                      <div class="ms-3">
                        <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-6 fw-bold" style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;display: block" :title="i.title"
                           data-kt-ecommerce-product-filter="product_name">{{ i.title }}</a>
                      </div>
                    </div>
                  </td>
                  <td class="text-center pe-0">
                    <span class="text-gray-800 fw-bold d-block fs-6">{{ i.price | format }}</span>
                  </td>
                </tr>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Table container-->
          </div>

        </div>
        <!--end::Tab Content-->
      </div>
      <!--end: Card Body-->
    </div>
  </div>

</template>
<script>
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";
import {getSaasGoodApi, getSaasGoodsInfoApi} from "@/api/home";

export default {
  name: "PlatformRecommendation",
  components: {GoodInfoComponent},
  data() {
    return {
      navList: [
        {
          title: '美食',
          imagesSrc: 'https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E9%A4%90%E9%A5%AE%E7%BE%8E%E9%A3%9F.png',
          list: [
            {
              "id": "100014930464",
              "title": "大叹号烤肉朝鲜族料理【夏日限定】",
              "price": "99.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E9%A3%9F/%E5%A4%A7%E5%8F%B9%E5%8F%B7%E7%83%A4%E8%82%89%E6%9C%9D%E9%B2%9C%E6%97%8F%E6%96%99%E7%90%86%E3%80%90%E5%A4%8F%E6%97%A5%E9%99%90%E5%AE%9A%E3%80%9199%E5%85%83.jpg",
              "disPrice": "9.9",
              "discount": "0.790",
              "isJd": "1"
            },
            {
              "id": "100014930464",
              "title": "翡翠青椒豆花烤鱼",
              "price": "149.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E9%A3%9F/%E7%BF%A1%E7%BF%A0%E9%9D%92%E6%A4%92%E8%B1%86%E8%8A%B1%E7%83%A4%E9%B1%BC%20149%E5%85%83.jpg",
              "disPrice": "9.9",
              "discount": "0.790",
              "isJd": "1"
            },
            {
              "id": "100014930464",
              "title": "麦旋风买1送1（奥利奥口味+草莓口味",
              "price": "14.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E9%A3%9F/%E9%BA%A6%E6%97%8B%E9%A3%8E%E4%B9%B01%E9%80%811%EF%BC%88%E5%A5%A5%E5%88%A9%E5%A5%A5%E5%8F%A3%E5%91%B3%2B%E8%8D%89%E8%8E%93%E5%8F%A3%E5%91%B3%EF%BC%89%2014%E5%85%83.jpg",
              "disPrice": "9.9",
              "discount": "0.790",
              "isJd": "1"
            }
          ]
        },
        {
          title: '美容美发',
          imagesSrc: 'https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91.png',
          list: [
            {
              "id": "100014930464",
              "title": "【夏日狂欢】设计师欧莱雅产品烫发",
              "price": "223.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91/%E3%80%90%E5%A4%8F%E6%97%A5%E7%8B%82%E6%AC%A2%E3%80%91%E8%AE%BE%E8%AE%A1%E5%B8%88%E6%AC%A7%E8%8E%B1%E9%9B%85%E4%BA%A7%E5%93%81%E7%83%AB%E5%8F%91%20223%E5%85%83.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },{
              "id": "100014930464",
              "title": "【盛夏美甲来袭】全贴款式不限",
              "price": "86.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91/%E3%80%90%E7%9B%9B%E5%A4%8F%E7%BE%8E%E7%94%B2%E6%9D%A5%E8%A2%AD%E3%80%91%E5%85%A8%E8%B4%B4%E6%AC%BE%E5%BC%8F%E4%B8%8D%E9%99%90%2086%E5%85%83.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },{
              "id": "100014930464",
              "title": "清痘祛痘 面部清颜60分钟",
              "price": "188.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91/%E6%B8%85%E7%97%98%E7%A5%9B%E7%97%98%20%E9%9D%A2%E9%83%A8%E6%B8%85%E9%A2%9C60%E5%88%86%E9%92%9F%20188%E5%85%83.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            }
          ]
        },
        {
          title: '休闲娱乐',
          imagesSrc: 'https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90.png',
          list: [
            {
              "id": "100014930464",
              "title": "【机关天花板】镇灵村密室逃脱",
              "price": "260.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90/qweqeqwe.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },  {
              "id": "100014930464",
              "title": "香薰石膏DIY",
              "price": "35.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90/xiangxun.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },  {
              "id": "100014930464",
              "title": "【质享·兰亭】足疗70分钟",
              "price": "169.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90/zuliao.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            }
          ]
        }
      ]
    };
  },
  methods: {
    openGoodInfo(id, isJD) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJD);
    },
    async getSaasList() {
      // let {data} = await getSaasGoodApi();
      // this.navList = []
      // data.splice(8)
      // data.forEach(async (e) => {
      //   let {data} = await getSaasGoodsInfoApi({
      //     categoryId: e.id
      //   })
      //   this.navList.push({
      //     title: e.title,
      //     imagesSrc: "https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/" + e.imgSrc,
      //     list: data
      //   })

      // })
    },
  },
  mounted() {
    this.getSaasList()
  }
}
</script>
