<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
          id="kt_content_container"
          class="container"
          style="
          margin-top: 10px;
          max-width: 100%;
          flex-direction: row !important;
          display: flex;
        "
      >
        <!-- 搜索开始 -->
        <div
            class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5"
        >
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <div class="mb-5">
                  <div class="nav-group nav-group-fluid">
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="changIsShow()"
                          value="0"
                          :checked="!this.params.isShow"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4"
                      >
                        全部商品
                      </span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="changIsShow(1)"
                          value="1"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4"
                      >
                        上架商品
                      </span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="changIsShow(2)"
                          value="2"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4"
                      >
                        下架商品
                      </span>
                    </label>
                  </div>
                </div>
                <div class="separator separator-dashed my-8"></div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >商品标题</label
                  >
                  <div class="position-relative">
                    <span
                        class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                      >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                          ></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <input
                        id="title"
                        type="text"
                        class="form-control form-control-solid ps-10"
                        name="search"
                        v-model="params.title"
                        value=""
                        placeholder="请输入需要查询的商品标题名称"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >一级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId1"
                      data-placeholder="全部"
                      @change="findCategory(1)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryOne"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >二级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId2"
                      @change="findCategory(2)"
                      data-placeholder="全部"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryTwo"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>

                <div v-if="role.includes('firstGradeSupplier') || role.includes('vipMember')" class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品来源</label>
                  <select class="form-select form-select-solid" v-model="params.goodsStaffId" data-placeholder="全部">
                    <option value="">全部</option>
                    <option v-for="(item, index) in staffArr" :key="index" :value="item.staffId">{{
                        item.companyName
                      }}
                    </option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">是否支持7天无理由</label>
                  <select class="form-select form-select-solid" v-model="params.sevenNRR" data-placeholder="全部">
                    <option value="">全部</option>
                    <option value="1">支持</option>
                    <option value="0">不支持</option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">发货时效</label>
                  <el-checkbox-group v-model="deliveryTime">
                    <el-checkbox label="24小时内发货"></el-checkbox>
                    <el-checkbox label="48小时内发货"></el-checkbox>
                    <el-checkbox label="48小时内发货(周末、节假日除外)"></el-checkbox>
                    <el-checkbox label="72小时内发货"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                  </el-checkbox-group>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >库存区间</label
                  >
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="minStock"
                          name="minStock"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          v-model="params.minStock"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最小值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>

                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="maxStock"
                          name="maxStock"
                          class="form-control form-control-lg form-control-solid"
                          v-model="params.maxStock"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最大值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                  <a
                      @click="shaixuan"
                      href="javascript:void(0);"
                      class="btn btn-primary"
                  >筛选</a
                  >&nbsp;&nbsp;&nbsp;
                  <!--btn-active-light-primary 点击变色-->
                  <a
                      @click="resetQuery();"
                      href="javascript:void(0);"
                      class="btn text-hover-primary bg-hover-light  btn-color-gray-400 me-3"
                  >重置</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div
            class="card mb-5 mb-xl-8"
            style="margin-left: 3px; width: 100%"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            v-loading="loading"
        >
          <div class="card-body py-3" style="padding-left: 15px">
            <div class="table-responsive">
              <table
                  class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                  id="kt_ecommerce_products_table"
              >
                <thead>
                <tr
                    class="text-start text-gray-500 fs-7 text-uppercase gs-0"
                >
                  <th
                      class="w-10px pe-2 sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label=""
                      style="max-width: 30px"
                  >
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          data-kt-check="true"
                          data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                          value="TRUE"
                          v-model="checkboxInput"
                          @change="checkboxInputEvent"
                      />
                    </div>
                  </th>
                  <th
                      tabindex="0"
                      aria-controls="kt_ecommerce_products_table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Product: activate to sort column ascending"
                  >
                    商品信息
                  </th>
                  <!-- <th class="min-w-20px" tabindex="0" aria-controls="kt_ecommerce_products_table"
                    rowspan="1" colspan="1"
                    aria-label="Product: activate to sort column ascending"
                    style="width: 20px;">商品图片</th>
                <th tabindex="0" aria-controls="kt_ecommerce_products_table"
                    rowspan="1" colspan="1" aria-label="Product: activate to sort column ascending">
                    商品标题</th> -->
                  <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分类"
                  >
                    分类
                  </th>
                  <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="市场价"
                  >
                    市场价
                  </th>
                  <th v-if="role.includes('firstGradeSupplier') || role.includes('vipMember')"
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销售价"
                  >
                    成本价
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销量"
                  >
                    销量
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="运费模板"
                  >
                    运费模板
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="库存"
                  >
                    库存
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品来源"
                  >
                    商品来源
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="状态"
                  >
                    状态
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品评论"
                  >
                    商品评论
                  </th>
                  <th
                      class="text-center "
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="更新时间"
                  >
                    更新时间
                  </th>
                  <th
                      class="text-center  sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="操作"
                  >
                    操作
                  </th>
                </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                <tr
                    v-for="(item, index) in rows"
                    :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                    :key="index"
                >
                  <!-- 复选按钮 -->
                  <td>
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          @click="addIdArr(item.id)"
                          :checked="idArr.includes(item.id)"
                      />
                    </div>
                  </td>
                  <!-- 商品信息 -->
                  <td style="padding-right: 0">
                    <div
                        class="d-flex align-items-center"
                        @click="
                          openGoodInfo(
                            item.isJD == 1 ? item.skuId : item.id,
                            item.isJD
                          )
                        "
                    >
                      <a href="javascript:void(0)" class="symbol symbol-50px">
                        <img
                            class="symbol-label lozad"
                            v-if="item.picUrl.indexOf('http') == 0"
                            :src="`${item.picUrl}`"
                        />
                        <img
                            class="symbol-label lozad"
                            v-else
                            :src="`https://www.3jzc.cn${item.picUrl}`"
                        />
                      </a>
                      <div
                          style="
                            max-width: 250px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-left: 10px;
                          "
                      >
                        <a
                            href="javascript:void(0)"
                            :title="item.title"
                            class="text-gray-800 text-hover-primary fs-5"
                            data-kt-ecommerce-product-filter="product_name"
                        >{{ item.title }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <!-- 分类 -->
                  <td class="text-center pe-0">
                    <div
                        style="font-size: 90%"
                        v-html="item.categoryName"
                    ></div>
                  </td>
                  <!-- 市场价 -->
                  <td
                      class="text-center pe-0 text-black"
                      @click="openEditPriceDialog(item)"
                  >
                      <span
                          class="text-warning text-hover-info border-hover-info cursor-pointer"
                          style="
                          font-size: 90%;
                          border: 1px solid rgba(9, 9, 9, 0.35);
                          padding: 10px;
                          border-radius: 10px;
                        "
                      >
                        {{ item.price | format }}
                      </span>
                  </td>
                  <!-- 成本价 -->
                  <td class="text-center pe-0" v-if="role.includes('firstGradeSupplier') || role.includes('vipMember')">
                      <span
                          class=" text-black text-hover-info border-hover-info cursor-pointer"
                          @click="openSalePriceDialog(item)"
                          style="
                          font-size: 90%;
                          border: 1px solid rgba(9, 9, 9, 0.35);
                          padding: 10px;
                          border-radius: 10px;
                        "
                      >
                        {{ item.salePrice | format }}</span
                      >
                  </td>
                  <!-- 销量 -->
                  <td class="text-center pe-0">
                      <span style="font-size: 90%">{{
                          item.saleNum
                        }}</span>
                  </td>
                  <!-- 运费模板 -->
                  <td class="text-center pe-0">
                      <span
                          style="
                          word-break: break-all;
                          overflow: hidden;
                          font-size: 90%;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                        "
                          :title="item.freightId"
                      >
                        {{ item.freightId }}
                      </span>
                  </td>
                  <!-- 库存 -->
                  <td class="text-center pe-0">
                      <span
                          class=" text-black text-hover-info border-hover-info cursor-pointer"
                          @click="editStock(item)"
                          style="
                          font-size: 90%;
                          border: 1px solid rgba(9, 9, 9, 0.35);
                          padding: 10px;
                          border-radius: 10px;
                        "
                      >{{ item.stock }}</span
                      >
                  </td>
                  <!-- 商品来源 -->
                  <td class="text-center pe-0">
                      <span style="font-size: 90%">{{
                          item.type
                        }}</span>
                  </td>
                  <!-- 状态 -->
                  <td class="text-center pe-0" v-if="role.includes('firstGradeSupplier')">
                    <div class="text-center" style="display: flex;justify-content: center;flex-direction: column;"
                         v-if="item.isShow.indexOf('已上架') >= 0">
                          <span class="badge badge-light-success fs-7"
                                style="display: block;
                                text-align: center;
                                margin: 0 auto;"
                                v-html="sArr(item.isShow)[0]">
                          </span>
                      <span class="text-muted text-muted d-block fs-7"
                            style="display:block;text-align:center;" v-html="sArr(item.isShow)[1]">
                          </span>
                    </div>
                    <div class="text-center" style="display: flex;justify-content: center" v-else>
                        <span :class="item.isShow == '待审核'? 'badge-light-primary':'badge-light-danger'"
                              class="badge fs-7"
                              style="display: block; text-align: center; width: 80px"
                              v-html="item.isShow"
                        ></span>
                    </div>
                  </td>
                  <td class="text-center pe-0" v-if="role.includes('secondTierSuppliers')">
                      <span class="badge badge-light-success fs-7 text-center"
                            style="display: block; text-align: center; margin: 0 auto;"
                            v-if="item.isTwoShow == 1">
                          已上架
                      </span>
                    <span class="badge badge-light-danger fs-7 text-center"
                          style="display: block; text-align: center; margin: 0 auto;"
                          v-if="item.isTwoShow == 2">
                            已下架
                      </span>
                    <span class="text-muted text-muted d-block fs-7"
                          style="display:block;text-align:center;"
                          v-if="item.showTime">
                        {{ item.showTime.substring(0, 10) }}
                    </span>
                  </td>

                  <td class="text-center pe-0" @click="openEvaluation(item)">
                    <span
                        class="text-black text-hover-info border-hover-info cursor-pointer"
                        style="
                          font-size: 90%;
                          border: 1px solid rgba(9, 9, 9, 0.35);
                          padding: 10px;
                          border-radius: 10px;
                          display: inline-block;">
                      <span class="text-muted d-block fs-7 text-center"
                            style="display: block; text-align: center; margin: 0 auto;">
                            总数:{{item.goodsEvaluationCount.totalCount}}
                      </span>
                      <span class="text-muted d-block fs-7"
                            style="display:block;text-align:center;">
                            今日:{{ item.goodsEvaluationCount.todayCount }}
                      </span>
                  </span>

                  </td>

                  <!-- 更新时间 -->
                  <td class="text-center pe-0">
                    <span>{{ item.crTime }}</span>
                  </td>
                  <!-- 操作 -->
                  <td class="text-center">
                    <a
                        href="#"
                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        @click="toggleDropdown(index)"
                    >
                      操作
                      <i class="ki-outline ki-down fs-5 ms-1"></i>
                    </a>
                    <div
                        :id="'dropdown-' + index"
                        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                    >
                      <div class="menu-item px-3">
                        <a
                            v-if="item.spuId"
                            href="javascript:void(0)"
                            class="menu-link px-3 "
                            @click="checkGoods(item.spuId)"
                        >查看同类商品</a
                        >
                      </div>
                      <div class="menu-item px-3"
                           v-if="(role.includes('firstGradeSupplier') && item.type == '自有') || (role.includes('secondTierSuppliers') && item.type == '自有')
                                  || (role.includes('vipMember') && item.type == '自有')">
                        <a
                            v-if="item.spuId"
                            href="javascript:void(0)"
                            class="menu-link px-3"
                            @click="
                            $router.push({
                                name: 'AddOrUpdateVisible',
                                params: { id: item.id,status:1 },
                              })
                            "
                        >新增同类商品</a
                        >
                      </div>

                      <div
                          class="menu-item px-3"
                          v-if="((role.includes('firstGradeSupplier')) && item.isShow.startsWith('未上架'))"
                      >
                        <a
                            href="javascript:void(0)"
                            class="menu-link px-3"
                            @click="updateIsShow(item, 1)"
                        >上架</a
                        >
                      </div>
                      <!--                      二级上架-->
                      <div
                          class="menu-item px-3"
                          v-if="(role.includes('secondTierSuppliers') && item.isTwoShow == 2)"
                      >
                        <a
                            href="javascript:void(0)"
                            class="menu-link px-3"
                        >上架</a
                        >
                      </div>
                      <div class="menu-item px-3" v-if="((role.includes('firstGradeSupplier')) && item.isShow.startsWith('已上架'))">
                        <a
                            href="javascript:void(0)"
                            class="menu-link px-3"
                            @click="updateIsShow(item, 2)"
                        >下架</a
                        >
                      </div>
                      <div class="menu-item px-3">
                        <a
                            v-if="((role.includes('firstGradeSupplier')|| role.includes('vipMember')) && item.type == '自有') || (role.includes('secondTierSuppliers') && item.type == '自有')"
                            href="javascript:void(0)"
                            class="menu-link px-3"
                            @click="
                              $router.push({
                                name: 'AddOrUpdateVisible',
                                params: { id: item.id },
                              })
                            "
                        >编辑</a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :total-items="totalCount"
                  @page-change="pageChange"
                  ref="Pagination"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  弹窗 -->
    <!-- 查看同类商品-->
    <el-dialog title="同类商品" :visible.sync="dialogTableVisible" width="90%">
      <table
          class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
          id="kt_ecommerce_products_table"
      >
        <thead>
        <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
          <th
              class="min-w-170px"
              tabindex="0"
              aria-controls="kt_ecommerce_products_table"
              rowspan="1"
              colspan="1"
              aria-label="Product: activate to sort column ascending"
              style="width: 17%"
          >
            商品信息
          </th>
          <th
              class="text-center"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="分类"
              style="width: 6%"
          >
            分类
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="市场价"
              style="width: 5%"
          >
            市场价
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="销售价"
              style="width: 4%"
          >
            成本价
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="销量"
              style="width: 4%"
          >
            销量
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="运费模板"
              style="width: 5%"
          >
            运费模板
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="库存"
              style="width: 4%"
          >
            库存
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="商品来源"
              style="width: 5%"
          >
            商品来源
          </th>
          <th
              class="text-center min-w-55px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="状态"
              style="width: 6%"
          >
            状态
          </th>
          <th
              class="text-center min-w-70px"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-label="更新时间"
              style="width: 9%"
          >
            更新时间
          </th>
        </tr>
        </thead>
        <tbody class="fw-semibold text-gray-600">
        <tr
            v-for="(item, index) in spuIdList"
            :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
            :key="index"
        >
          <!-- 商品信息 -->
          <td style="padding-right: 0">
            <div
                class="d-flex align-items-center"
            >
              <a href="javascript:void(0)" class="symbol symbol-50px">
                <!-- <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http')==0"
                          src="../../assets/images/spinner.gif" :data-src="`${item.picUrl}`">
                      <img class="symbol-label lozad" v-else
                      src="../../assets/images/spinner.gif" :data-src="`https://www.3jzc.cn${item.picUrl}`"> -->
                <img
                    class="symbol-label lozad"
                    v-if="item.picUrl.indexOf('http') == 0"
                    :src="`${item.picUrl}`"
                />
                <img
                    class="symbol-label lozad"
                    v-else
                    :src="`https://www.3jzc.cn${item.picUrl}`"
                />
              </a>
              <div
                  style="
                    max-width: 170px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    margin-left: 10px
                  "
              >
                <a
                    href="javascript:void(0)"
                    :title="item.title"
                    class="text-gray-800 text-hover-primary fs-5 fw-bold"
                    data-kt-ecommerce-product-filter="product_name"
                >{{ item.title }}</a
                >
              </div>
            </div>
          </td>
          <!-- 分类 -->
          <td class="text-center pe-0">
            <div
                class="fw-bold"
                style="font-size: 90%"
                v-html="item.categoryName"
            ></div>
          </td>
          <!-- 市场价 -->
          <td
              class="text-center pe-0 text-black fw-bold"
          >
              <span
                  class="fw-bold text-hover-info border-hover-info cursor-pointer"
                  style="
                  font-size: 90%;
                  padding: 10px;
                "
              >
                {{ item.price | format }}
              </span>
          </td>
          <!-- 成本价 -->
          <td class="text-center pe-0">
              <span
                  class="fw-bold text-black fw-bold text-hover-info border-hover-info cursor-pointer"
                  style="
                  font-size: 90%;
                "
              >
                {{ item.salePrice | format }}</span
              >
          </td>
          <!-- 销量 -->
          <td class="text-center pe-0">
              <span class="fw-bold" style="font-size: 90%">{{
                  item.saleNum
                }}</span>
          </td>
          <!-- 运费模板 -->
          <td class="text-center pe-0">
              <span
                  class="fw-bold"
                  style="
                  word-break: break-all;
                  overflow: hidden;
                  font-size: 90%;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;"
                  :title="item.freightId"
              >
                {{ item.freightId }}
              </span>
          </td>
          <!-- 库存 -->
          <td class="text-center pe-0">
              <span
                  class=""
                  style="
                  font-size: 90%
                "
              >{{ item.stock }}</span
              >
          </td>
          <!-- 商品来源 -->
          <td class="text-center pe-0">
              <span class="fw-bold" style="font-size: 90%">{{
                  item.type
                }}
            </span>
          </td>
          <!-- 状态 -->
          <td class="text-center pe-0" v-if="role.includes('firstGradeSupplier')">
            <div class="text-center" style="display: flex;justify-content: center;flex-direction: column;"
                 v-if="item.isShow.indexOf('已上架') >= 0">
               <span class="badge badge-light-success fs-7"
                     style="
                            display: block;
                            text-align: center;
                            margin: 0 auto;
                          "
                     v-html="sArr(item.isShow)[0]"></span>
              <span class="text-muted text-muted d-block fs-7"
                    style="display:block;text-align:center;" v-html="sArr(item.isShow)[1]"></span>
            </div>
            <div class="text-center" style="display: flex;justify-content: center" v-else>
            <span
                :class="item.isShow == '待审核'? 'badge-light-primary':'badge-light-danger'"
                class="badge fs-7"
                style="display: block; text-align: center; width: 80px"
                v-html="item.isShow"
            ></span>
            </div>
          </td>
          <td class="text-center pe-0" v-if="role.includes('secondTierSuppliers')">
                      <span class="badge badge-light-success fs-7 text-center"
                            style="display: block; text-align: center; margin: 0 auto;"
                            v-model="item.isTwoShow" v-if="item.isTwoShow == 1">
                          已上架
                      </span>
            <span class="badge badge-light-danger fs-7 text-center"
                  style="display: block; text-align: center; margin: 0 auto;"
                  v-model="item.isTwoShow" v-if="item.isTwoShow == 2">
                            已下架
                      </span>
            <span class="text-muted text-muted d-block fs-7"
                  style="display:block;text-align:center;"
                  v-if="item.showTime">
                        {{ item.showTime.substring(0, 10) }}
                    </span>
          </td>
          <!-- 更新时间 -->
          <td class="text-center pe-0">
            <span class="fw-bold">{{ item.crTime }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </el-dialog>
    <!-- 商品详情 -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>
    <!-- 修改市场价 -->
    <ModalDialog
        title="修改市场价"
        id="editPriceModalDialog"
        ref="editPriceModalDialog"
        width="500px"
    >
      <span class="d-inline-block">市场价</span>
      <el-input
          class="ms-5 d-inline-block w-50"
          v-model="editObj.price"
      ></el-input>
      <div style="display: flex;justify-content: center;margin-top: 30px">
        <el-button @click="openEditPriceDialog(0)">取 消</el-button>
        <el-button type="primary" @click="editPrice()">确 定</el-button>
      </div>
    </ModalDialog>


    <!-- 修改成本价 -->
    <ModalDialog
        title="修改成本价"
        id="editSalePriceModalDialog"
        ref="editSalePriceModalDialog"
        width="500px"
    >
      <span class="d-inline-block">成本价</span>
      <el-input
          class="ms-5 d-inline-block w-50"
          v-model="editObj.salePrice"
      ></el-input>

      <div style="display: flex;justify-content: center;margin-top: 30px">
        <el-button @click="openSalePriceDialog(0)">取 消</el-button>
        <el-button type="primary" @click="editSalePrice()">确 定</el-button>
      </div>
    </ModalDialog>
    <!-- 修改库存 -->
    <ModalDialog
        title="修改库存"
        id="editStockModalDialog"
        ref="editStockModalDialog"
        width="500px"
    >
      <span class="d-inline-block">库存</span>
      <el-input
          class="ms-5 d-inline-block w-50"
          v-model="editStockObj.stock"
      ></el-input>
      <span
          class="mt-2 d-block dialog-footer justify-content-center align-items-center"
      >
        <el-button @click="$refs.editStockModalDialog.close()">取 消</el-button>
        <el-button type="primary" @click="saveStock()">确 定</el-button>
      </span>
    </ModalDialog>

    <GoodsEvaluation ref="goodsEvaluation"></GoodsEvaluation>

  </div>
</template>
<script>

// 导入数据api
import {
  getExportSjGoodsListApi,
  getSjGoodsListApi,
  getSjGoodsListBySpuIdApi,
  getSjGoodsListCountApi,
  getStaffListApi,
  updateGoodsInfoOfSecondaryOperatorsApi,
  updateIsQuanApi,
  updateIsShowApi,
  updatePoolPriceApi,
  updateSjGoodsPriceApi,
  updateSjGoodsSalePriceApi,
  updateSjGoodsSupplPriceApi,
  updateStockApi
} from "@/api/goodsManageApi";
import GoodInfoComponent from "@/components/GoodInfoComponent";
import GoodsEvaluation from "@/views/Goods/components/GoodsManage/components/GoodsEvaluation/index.vue";
// 页码指定跳转
import Pagination from "@/components/Pagination/index.vue";
import {getCategoryList} from "@/api/categoryApi";
import sd from "silly-datetime";

export default {
  components: {
    Pagination,
    GoodInfoComponent,
    GoodsEvaluation
  },
  data() {
    return {
      dialogTableVisible: false,
      staffArr: [],
      categoryOne: [],
      categoryTwo: [],
      idArr: [],
      checkboxInput: false,
      rows: [],
      loading: false,
      params: {
        current: 1,
        size: 10,
        phone: "",
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: "",
        isTwoShow: "",
        stock: "",
        isQuan: "",
        goodsStaffId: "",
        minStock: "",
        maxStock: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        deliveryTime: ""
      },
      deliveryTime: [],
      poolSupplPrice: "",
      totalCount: 999,
      first: true,
      editObj: {
        ids: [],
        freightId: "",
        sevenNRR: "",
        price: "",
        supplPrice: "",
        quanSupplyPrice: "",
        salePrice: "",
        ratio: "",
        stock: "",
        isShow: 2,
        isQuan: 2,
      },
      editStockObj: {
        ids: [],
        stock: 0,
      },
      spuIdList: [],
      role: []
    };
  },
  computed: {
    zzxwRatio() {
      return this.toFixedCeil(
          this.editObj.supplPrice / (1 - this.editObj.ratio * 0.01),
          2
      );
    },
  },
  // 方法
  methods: {
    reloadIdArr() {
      this.idArr = [];
      this.$emit("setArrId", this.idArr)
    },
    setArrId() {
      this.$emit("setArrId", this.idArr)
    },
    sArr(text) {
      return text.split("<br>")
    },
    //重置
    resetQuery() {
      this.deliveryTime = [];
      this.params = {
        current: 1,
        size: 10,
        phone: "",
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: this.params.isShow,
        isTwoShow: this.params.isTwoShow,
        stock: "",
        isQuan: "",
        goodsStaffId: "",
        minStock: "",
        maxStock: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        deliveryTime: ""
      }
      this.first = true
      this.getSjGoodsList()
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArr.includes(e.id)) {
            this.idArr.push(e.id);
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArr.indexOf(e.id);
          if (index !== -1) {
            this.idArr.splice(index, 1);
          }
        });
      }
      this.setArrId()
    },
    async addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
      this.setArrId()

    },
    async checkGoods(spuId) {
      let {data} = await getSjGoodsListBySpuIdApi({
        spuId,
      });
      this.spuIdList = data.rows;
      this.dialogTableVisible = true;
    },
    // 修改状态
    async updateIsShow(item, isShow) {
      // 一级上架二级的商品
      if ((this.role.includes('firstGradeSupplier')) && isShow == 1 && item.type != '自有') {
        // 已有值
        if (!item.salePrice && !item.ratio) {
          this.editObj = {
            ids: [],
            freightId: "",
            sevenNRR: "",
            price: "",
            supplPrice: "",
            quanSupplyPrice: "",
            salePrice: "",
            ratio: "",
            stock: "",
            isShow: 1,
            isQuan: 2,
          };
        } else {
          this.editObj = {
            ids: [item.id],
            freightId: "",
            sevenNRR: "",
            price: item.price,
            supplPrice: item.supplPrice,
            quanSupplyPrice: "",
            salePrice: item.salePrice,
            ratio: item.ratio,
            stock: "",
            isShow: 1,
            isQuan: item.isQuan,
          };
        }
        this.$refs.editSecondOpertaion.open();
        return
      }
      if ((this.role.includes('firstGradeSupplier')) && isShow == 1 && (!item.salePrice || !item.ratio)) {
        this.$message.error("请先填写销售价以及分销价加价比例")
        return
      }

      let msg = isShow == 1 ? "上架" : isShow == 2 ? "下架" : isShow == 3 ? "删除" : "未知状态";
      this.$confirm(`你确定${msg}该商品 `, `是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(async () => {
        await updateIsShowApi(
            JSON.stringify({
              ids: [item.id],
              isShow: isShow,
            })
        );
        this.$message({
          type: "success",
          message: msg + "成功!",
        });
        this.getSjGoodsList();
      }).catch(() => {
      });
    },

    // 打开弹窗并修改市场价
    openEditPriceDialog(item) {
      if (item) {
        this.$refs.editPriceModalDialog.open();
        this.editObj.ids = Array.from([item.id]);
        this.editObj.price = item.price;
      } else {
        this.$refs.editPriceModalDialog.close();
        this.editObj = {
          ids: [],
          freightId: "",
          sevenNRR: "",
          price: "",
          supplPrice: "",
          quanSupplyPrice: "",
          salePrice: "",
          ratio: "",
          stock: "",
          isShow: 2,
          isQuan: 2,
        };
      }
    },
    //修改市场价
    async editPrice() {
      await updateSjGoodsPriceApi(JSON.stringify(this.editObj));
      this.$message({
        message: "修改成功",
        type: "success",
      });
      this.$refs.editPriceModalDialog.close();
      this.getSjGoodsList();
      this.editObj = {
        ids: [],
        freightId: "",
        sevenNRR: "",
        price: "",
        supplPrice: "",
        quanSupplyPrice: "",
        salePrice: "",
        ratio: "",
        stock: "",
        isShow: 2,
        isQuan: 2,
      };
    },
    toFixedCeil(num, decimal) {
      return (
          Math.ceil(num * Math.pow(10, decimal)) / Math.pow(10, decimal)
      ).toFixed(decimal);
    },
    // 打开或关闭销售价弹窗
    openSalePriceDialog(item) {
      if (item) {
        this.$refs.editSalePriceModalDialog.open();
        this.editObj.ids = Array.from([item.id]);
        this.editObj.salePrice = item.salePrice;
      } else {
        this.$refs.editSalePriceModalDialog.close();
        this.editObj = {
          ids: [],
          freightId: "",
          sevenNRR: "",
          price: "",
          supplPrice: "",
          quanSupplyPrice: "",
          salePrice: "",
          ratio: "",
          stock: "",
          isShow: 2,
          isQuan: 2,
        };
      }
    },
    //修改成本价
    async editSalePrice(item) {
      await updateSjGoodsSalePriceApi(JSON.stringify(this.editObj));
      this.$message({
        message: "修改成功",
        type: "success",
      });
      this.$refs.editSalePriceModalDialog.close();
      this.getSjGoodsList();
      this.editObj = {
        ids: [],
        freightId: "",
        sevenNRR: "",
        price: "",
        supplPrice: "",
        quanSupplyPrice: "",
        salePrice: "",
        ratio: "",
        stock: "",
        isShow: 2,
        isQuan: 2,
      };
    },
    //修改库存
    editStock(item) {
      if ((this.role.includes('firstGradeSupplier') || this.role.includes('vipMember')) && item.type != "自有") {
        this.$message.warning("该商品为其他供应商，无法修改商品库存")
        return
      }
      this.editStockObj.ids = [item.id];
      this.editStockObj.stock = item.stock;
      this.$refs.editStockModalDialog.open();
    },
    // 保存库存
    async saveStock() {
      if (!(/^-?\d+$/.test(this.editStockObj.stock)) || this.editStockObj.stock <= 0) {
        this.$message.warning("请输入正整数")
        return
      }
      let {data} = await updateStockApi(JSON.stringify(this.editStockObj));
      this.$message({
        message: "修改成功",
        type: "success",
      });
      this.getSjGoodsList();
      this.$refs.editStockModalDialog.close();
    },
    // 打开商品信息
    openGoodInfo(id, isJd) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
    },
    // 导出商品数据到excel
    async exp() {
      let obj = this.params;
      obj.current = null;
      obj.size = null;
      let data = await getExportSjGoodsListApi(obj);
      var blob = new Blob([data], {type: "application/octet-stream"});
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;

      a.download = `自有商品信息-${sd.format(new Date(), "YYYY-MM-DD")}.xls`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    // 导出数据到ppt
    async exportPPT() {

    },
    // 传递id
    editEvent(id) {
      this.$emit("editEvent", id);
    },
    // 分页查询
    async getSjGoodsList() {
      if (this.deliveryTime.length > 0) {
        this.params.deliveryTime = this.deliveryTime.join(',');
      } else {
        this.params.deliveryTime = "";
      }
      if (this.role.includes('firstGradeSupplier')) {
        this.params.isTwoShow = 1
      }
      this.checkboxInput = false;
      this.loading = true;
      let {data} = await getSjGoodsListApi(this.params);
      this.loading = false;
      this.rows = data.rows;
      if (this.first) {
        this.getSjGoodsPageCount();
      }

      this.first = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
      this.setArrId()
    },
    // 分页
    pageChange(currentPage, pageSize) {
      this.params.current = currentPage;
      this.params.size = pageSize;

      this.getSjGoodsList();
    },
    // 求和
    async getSjGoodsPageCount() {
      let {data} = await getSjGoodsListCountApi(this.params);
      this.totalCount = data;
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    // 自有商品上下架切换
    changIsShow(index) {
      this.idArr = []
      this.first = true;
      if (this.role.includes('secondTierSuppliers') && index) {
        this.params.isShow = "";
        this.params.isTwoShow = index;
      } else if ((this.role.includes('firstGradeSupplier') || this.role.includes('vipMember')) && index) {
        this.params.isShow = index;
        this.params.isTwoShow = 1;
      }
      if (!index) {
        this.params.isShow = "";
        this.params.isTwoShow = "";
      }
      this.getSjGoodsList();
    },
    // 筛选
    shaixuan() {
      this.idArr = []
      this.first = true;
      if (this.params.current != 1) {
        this.$refs.Pagination.goToPage(1);
      } else {
        this.getSjGoodsList();
      }
    },
    // 查询分类
    async findCategory(index) {
      this.first = true;
      // this.params.isShow = index;
      if (!this.params.categoryId1) {
        this.params.categoryId1 = 0;
      }
      if (index == 1) {
        let {data} = await getCategoryList({
          parentId: this.params.categoryId1,
        });
        this.categoryTwo = data.list;
      }
      if (this.params.categoryId1 === 0) {
        this.params.categoryId1 = "";
      }
      this.getSjGoodsList();
    },
    //初始化分类
    async initCategory() {
      let {data} = await getCategoryList({
        parentId: 0,
      });
      this.categoryOne = data.list;
    },
    // 获取员工列表
    async getStaffList() {
      let {data} = await getStaffListApi();
      this.staffArr = data;
    },
    /**
     * 获取角色权限
     */
    getLoginRole() {
      this.role = localStorage.getItem('role')
    },
    openEvaluation(item){
      this.$refs.goodsEvaluation.init(item.id)
    }
  },
  // 父类index使用
  createGoods() {
  },
  // 初始化这个方法
  created() {
    this.getLoginRole();
    this.getSjGoodsList();
    this.initCategory();
    this.getStaffList();
  },
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      this.toggleDropdown(index);
    });
  },
};
</script>
<!--组件样式-->
<style scoped>
.borderHover:hover {
  border-color: rgb(0, 158, 247) !important;
}

/* 修改滚动条样式 */
.table-responsive::-webkit-scrollbar {
  width: 20px; /* 设置滚动条宽度 */
  height: 15px;
  background-color: #F5F5F5; /* 设置滚动条背景颜色 */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条滑块颜色 */
  border-radius: 5px; /* 设F置滚动条滑块圆角 */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块在鼠标悬停时的颜色 */
}
</style>
