import {
    backDetailApi,
    backReplyApi,
    queryGoodsEvaluationPageApi
} from "@/api/lxh/applet/evaluation";
import Pagination from "@/components/Pagination/index.vue";
export const mixins = {
    components:{
        Pagination
    },
    data(){
        return{
            evaluationListDialogShow:false,
            viewDialogShow:false,
            modeType:1,
            options:[],
            params:{
                goodsId:"",
                goodsType:2,
                current:1,
                size:10,
            },
            rows:[],
            total:0,
            detailInfo:{
                content:"aaaaaaaaa",
                replyContent:"bbbbbbb",
                fileList:[{
                    fullUrl:"",
                    name:""
                }]
            }
        }
    },

    methods:{
        async getList(){
            let {data}  = await queryGoodsEvaluationPageApi(this.params)
            this.rows = data.rows
            this.total = data.total
        },
        init(goodsId){
            this.evaluationListDialogShow = true
            this.params.goodsId = goodsId
            this.getList()
        },
        search(){
            this.params.current=1
            this.getList()
        },
        pageChange(currentPage, pageSize) {
            this.params.current = currentPage;
            this.params.size = pageSize;

            this.getList();
        },
        resetSearch(){
            this.params.comboId = ''
            this.search()
        },
        async viewThis(item){

            let {data} = await backDetailApi({uuid:item.uuid})
            this.detailInfo = data
            this.modeType = 1
            this.viewDialogShow = true
        },
        async replyThis(item){
            let {data} = await backDetailApi({uuid:item.uuid})
            this.detailInfo = data
            console.log(data)
            this.viewDialogShow = true
            this.modeType = 2

        },

        async replySubmit(){
            let param = {
                uuid:this.detailInfo.uuid,
                replyContent:this.detailInfo.replyContent
            }
            console.log(param)
            let res  = await backReplyApi(param)
            if(res.code === 200){
                this.$message({
                    type: 'success',
                    message: '回复成功!'
                });
                this.viewDialogShow = false
                this.getList()
            }else{
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }

    },
    created() {
    }
}
