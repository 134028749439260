<template>
  <div>
    <el-dialog
      title="商品评论列表"
      :visible.sync="evaluationListDialogShow"
      width="90%"
    >
      <div class="card bg-light shadow-sm" style="background: #fff;margin-top: 10px;border-radius: 15px" v-if="false">
        <div class="card-body"
             style="background: #fff;border-radius: 15px;width: 100%">
          <div>
            <div style="display:flex;justify-content: space-between;align-items: center">
              <div style="display: flex;align-items: center">
                <div class="position-relative w-md-400px me-md-2">
                    <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                      <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                           xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><rect height="24" width="24"
                                                                                                x="0" y="0"></rect><path
                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path><path
                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                            fill="#000000" fill-rule="nonzero"></path></g></svg>
                    </span>
                  <div class="el-input el-input--prefix">
                    <el-select v-model="params.articleUuid" placeholder="选择文章查询" class="store_openingHours">
                      <el-option
                          v-for="item in options"
                          :key="item.uuid"
                          :label="item.title"
                          :value="item.uuid">
                      </el-option>
                    </el-select>
                    <span class="el-input__prefix">
                        <i class="el-input__icon el-icon-search"></i>
                      </span>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <el-button type="primary" size="small" round @click="search">查询</el-button>
                  <el-button size="small" round @click="resetSearch">重置</el-button>
                  <a class="btn btn-link" style="margin-left: 10px"
                     id="kt_horizontal_search_advanced_link"
                     data-bs-toggle="collapse"
                     href="#kt_advanced_search_form1"
                  >
                    高级查询
                  </a>
                </div>
              </div>
              <!--            <div>-->
              <!--              <el-button type="primary" size="mini" round @click="createArticle">新增</el-button>-->
              <!--            </div>-->
            </div>
            <div id="kt_advanced_search_form1" class="collapse">
              <div class="separator separator-dashed mt-9 mb-6"></div>
              <div style="display: flex">
                <div class="col-lg-3">

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <el-row style="margin-top: 10px">

        <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px">
          <div class="tab-content">
            <div
                class="tab-pane fade show active">
              <div class="d-flex flex-column gap-7 gap-lg-10">
                <div class="card card-flush"
                     style="border-radius:15px !important; ">
                  <div class="card-body pt-0">
                    <div class="row" style="padding-left: 0;padding-right: 0;padding-bottom: 0">
                      <div class="table-responsive" style="display:flex;min-height: 54vh">
                        <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                          <thead>
                          <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              手机号
                            </th>
                            <th tabindex="0" rowspan="1" colspan="1" class="text-center" style="width:200px;">
                              评论内容
                            </th>

                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              评论时间
                            </th>

                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              回复内容
                            </th>

                            <th rowspan="1" colspan="1" class="text-center sorting_disabled" style="width:100px;">
                              操作
                            </th>
                          </tr>
                          </thead>
                          <tbody class="fw-semibold text-gray-600">
                          <tr class="old" v-for="(item,index) in rows" :key="index">
                            <td class="text-center pe-0">
                              {{ item.userTel }}
                            </td>
                            <td class="text-center pe-0">
                              <span class="text-clamp-two-lines" :title="item.content">{{item.content}}</span>
                            </td>
                            <td class="text-center pe-0">
                              {{ item.createTime }}
                            </td>
                            <td class="text-center pe-0">
                              <span class="text-clamp-two-lines" :title="item.replyContent">{{!item.replyContent?'未回复':item.replyContent}}</span>
                            </td>
                            <td class="text-center">
                                                            <el-link type="primary" @click="viewThis(item)">查看</el-link>
                              <el-link v-if="!item.replyContent" type="success" @click="replyThis(item)" style="margin-left: 10px">回复</el-link>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style="display: flex;justify-content: center">
                      <Pagination
                          :total-items="total"
                          @page-change="pageChange"
                          ref="Pagination"
                      ></Pagination>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </el-row>
    </el-dialog>


    <el-dialog
      :title="modeType===1?'评价详情':'回复'"
      :visible.sync="viewDialogShow"
      modal-append-to-body="true"
    >
      <div style="display: flex; flex-direction: column; height: 100%;">
        <el-card>
            <el-row>客户评论</el-row>
            <el-row>
              <el-input type="textarea" v-model="detailInfo.content" readonly></el-input>
            </el-row>
            <el-row style="margin-top: 10px">
              <div v-for="(item,index) in detailInfo.fileDomainList" :key="index">
                <el-image :src="item.fullUrl" style="width: 100px;height: 100px;margin-right: 10px"></el-image>
              </div>
            </el-row>
        </el-card>

        <el-card style="margin-top: 10px">
          <el-row>商家回复</el-row>
          <el-row>
            <el-input type="textarea" v-model="detailInfo.replyContent" :readonly="modeType===1"></el-input>
          </el-row>
          <el-row type="flex" justify="end" style="margin-top: 10px">
            <el-button size="mini" type="primary" @click="replySubmit" v-if="modeType===2">提交</el-button>
          </el-row>

        </el-card>

      </div>
    </el-dialog>

  </div>
</template>
<script>

import {mixins} from "@/views/Goods/components/GoodsManage/components/GoodsEvaluation/mixins";
export default {
  components: {},
  mixins: [mixins]
}
</script>

<style scoped>

.text-clamp-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
