import Clipboard from "clipboard";
import {getStoreOnLineCategoryListApi, updateOnLineStoreApi} from "@/api/lxh/store";
import {getPlaceSuggestionApi} from "@/api/qq-map";
import {qualificationType} from "@/utils/qualificationType";

let id = 0;
export const mixins = {
    data(){
        return{
            storeInfo: {
                id:"",
                storeName: "",
                categoryId2:"",
                categoryId3:"",
                categoryId4:"",
                branchName: "",
                storeTel: "",
                storeLogo: "",
                storeLat: 39.908823,
                storeLng: 116.39747,
                storeAddress: "",
                storeCity:"",
                chooseAddress:"",
                businessUrl:"",
                qualificationUrl:[

                ],
                collection:false
            },
            categoryList: [],
            timeout: null,
            mapLocation: {
                lat: "",
                lng: ""
            },
            map: null,
            marker: null,
            fileIndex:0,
            useType:"logo",
            qualificationType,
            category2List:[],
            category3List:[],
            category4List:[],
            dialogVisible:false,
        }
    },
    filters: {
        qualificationTypeF(value){
            let item = qualificationType.filter(item => {
                return  item.value === value
            });
            return item ? item[0].label : '';
        }
    },
    methods:{
        async changeCategory(e){
            if (e===2){
                this.category3List = await this.getStoreCategoryList(this.storeInfo.categoryId2)
                this.category4List=[]
            }else{
                this.category4List = await this.getStoreCategoryList(this.storeInfo.categoryId3)
            }
        },
        async getStoreCategoryList(parentId) {
            let {data} = await getStoreOnLineCategoryListApi({
                parentId
            })
            return data;
        },
        addQualification(){
            this.storeInfo.qualificationUrl.push({
                img:"",
                type:2,
            })
        },
        handleClose(done) {
            done();
        },

        /**
         * 保存信息
         *
         */
        async saveStore(){
            this.$confirm('保存后进入审核, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await updateOnLineStoreApi(this.storeInfo)
                this.$message({
                    message: '保存成功！',
                    type: 'success'
                });
                this.dialogVisible = false
                this.$emit("getList")
            }).catch(() => {

            });

        },
        /**
         * 复制方法
         */
        copyStoreId(){
            let clipboard = new Clipboard(".copy_store_id");
            clipboard.on("success", e => {
                // 释放内存
                this.$message({
                    message: "复制成功",
                    type: "success"
                });
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$message({
                    message: "该浏览器不支持自动复制",
                    type: "warning"
                });
                // 释放内存
                clipboard.destroy();
            });
        },
        handleSelect(e){
            this.mapLocation={
                lat:e.location.lat,
                lng:e.location.lng
            }
            this.storeInfo.storeCity=e.city
            this.storeInfo.storeLat=this.mapLocation.lat
            this.storeInfo.storeLng=this.mapLocation.lng
            this.updateMarker()
            this.storeInfo.chooseAddress=e.address
            this.storeInfo.storeAddress=e.title
        },
        /**
         * 搜索
         */
         querySearchAsync(queryString, cb){
            clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                let {data} = await getPlaceSuggestionApi({region: this.storeInfo.storeCity, keyword: queryString})
                let arr=JSON.parse(data);
                cb(arr.data);
            }, 100);
        },
        async init(item){
            this.destroyMap()
            let qArr=[];
            item.qualificationUrl.forEach(i=>{
                if(i.type===1){
                    item.businessUrl=i.img
                }else{
                    qArr.push({
                        img:i.img,
                        type:i.type
                    })
                }
            })
            item.qualificationUrl=qArr
            this.storeInfo=item
            this.dialogVisible=true
            this.$nextTick(()=>{
                this.initMap()
            })
            this.category2List = await this.getStoreCategoryList(item.categoryId1)
            this.category3List = await this.getStoreCategoryList(item.categoryId2)
            this.category4List = await this.getStoreCategoryList(item.categoryId3)

        },
        /**
         * 删除地图
         */
        destroyMap() {
            if (this.map) {
                // 销毁地图实例
                this.map.destroy();
                this.map = null;
            }
        },
        /**
         * 初始化地图
         */

        initMap() {
            //定义地图中心点坐标
            var center = new TMap.LatLng(this.storeInfo.storeLat, this.storeInfo.storeLng)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.map = new TMap.Map(document.getElementById('containerStoreEditMap'), {
                center: center,//设置地图中心点坐标
                zoom:18
            });

            // 添加初始标记
            this.marker = new TMap.MultiMarker({
                map: this.map,
                styles: {
                    marker: new TMap.MarkerStyle({
                        "width": 25,
                        "height": 35,
                        "anchor": { x: 16, y: 32 }
                    })
                },
                geometries: [{
                    id: "marker",
                    styleId: "marker",
                    position: new TMap.LatLng(this.storeInfo.storeLat, this.storeInfo.storeLng)
                }]
            });

        },
        openMaterial(type,index){
            this.fileIndex=index;
            this.useType=type;
            switch (type){
                case 'logo':
                    this.$refs.MaterialComponent.init(1)
                    break;
                case 'business':
                    this.$refs.MaterialComponent.init(1)
                    break;
                case 'qualification':
                    this.$refs.MaterialComponent.init(1)
                    break;
            }
        },
        useImg(item){
            let obj=this.storeInfo
            switch (this.useType){
                case 'logo':
                    obj.storeLogo=item[0].fullUrl
                    this.storeInfo=obj;
                    break;
                case 'business':
                    obj.businessUrl=item[0].fullUrl
                    this.storeInfo=obj;
                    break;
                case 'qualification':
                    obj.qualificationUrl[this.fileIndex].img=item[0].fullUrl
                    this.storeInfo=obj;
                    break;
            }
        },
        /**
         * 更新标记点位置
         */
        updateMarker() {
            this.marker.setGeometries([{
                id: "marker",
                styleId: "marker",
                position: new TMap.LatLng(this.storeInfo.storeLat, this.storeInfo.storeLng)
            }]);
            // 更新地图中心位置
            this.map.setCenter(new TMap.LatLng(this.storeInfo.storeLat, this.storeInfo.storeLng));
        }
    },
    created() {

    }
}
