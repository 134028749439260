import service from "@/utils/request"


export function queryComboEvaluationPageApi(params){
    return service({
        url: "/evaluation/backComboQueryPage",
        method: "get",
        params
    })
}

export function queryGoodsEvaluationPageApi(params){
    return service({
        url: "/evaluation/backGoodsQueryPage",
        method: "get",
        params
    })
}

export function queryArticleEvaluationPageApi(params){
    return service({
        url: "/evaluation/backArticleQueryPage",
        method: "get",
        params
    })
}

export function backDetailApi(params){
    return service({
        url: "/evaluation/backDetail",
        method: "get",
        params
    })
}

export function backReplyApi(data){
    return service({
        url: "/evaluation/backReply",
        method: "post",
        data
    })
}